require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('inlogpagina', undefined);

  },
  /* handler:onClick *//* content */
}));

define('./pages/algemenegegevens/components/da41d212ae6863d38204da52c1b9e1f8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function () { window.history.back(); })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/algemenegegevens/components/f9fe51eeb3c7a45d74551f98e3b8b37e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function () { window.history.back(); })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/algemenegegevens/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      if (!(await Backendless.UserService.isValidLogin())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('inlogpagina', undefined);
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/algemenegegevens/components/2adafcd487e2cf4a306d6bb893179bfe/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('algemenegegevens').bulkUpdate( (['hospice=\'',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'hospice')),'\''].join('')), ({ 'locatie': ___arguments.value }) );

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/algemenegegevens/components/1171408bf118545ef245f98608ba38f1/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('1171408bf118545ef245f98608ba38f1', (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['username=\'',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'username')),'\''].join(''))).setPageSize(10))));

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/algemenegegevens/components/448f6e21c6e160197d199b5f0e5533a5/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('Users').bulkUpdate( (['username=\'',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'username')),'\''].join('')), ({ 'naam': ___arguments.value }) );

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/algemenegegevens/components/3ca2069d55e252d465c771dd840174ac/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'emailupdate')) == null) {
    (function (message) { alert(message) })('Er is geen tekst ingevuld om te verzenden via mail.');
  } else if ((getObjectProperty(___arguments.context.pageData, 'emailupdate')) == 'DE EMAIL IS VERZONDEN') {
    (function (message) { alert(message) })('De email is reeds verzonden');
  } else {
    await Backendless.Messaging.sendEmailFromTemplate('useraanpassingen', Backendless.EmailEnvelope.create({ addresses: 'info@lorio.nl', ccAddresses: (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'email')), bccAddresses: ['backendless@lorio.nl'], query: '', uniqueEmails: true }), ({ [`naam`]: (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'naam')),[`gebruiker`]: (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'username')),[`hospice`]: (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'hospice')),[`emailupdate`]: (getObjectProperty(___arguments.context.pageData, 'emailupdate')),[`email2`]: (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'email')) }), undefined);
    ___arguments.context.pageData['emailupdate'] = ('De email is verzonden'.toUpperCase());
    await new Promise(r => setTimeout(r, 3000 || 0));
    ___arguments.context.pageData['emailupdate'] = null;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/algemenegegevens/components/d75477565dfc88fc99f5f35d59c2a709/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d75477565dfc88fc99f5f35d59c2a709', (await Backendless.Data.of('algemenegegevens').find(Backendless.DataQueryBuilder.create().setWhereClause((['hospice=\'',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'hospice')),'\''].join(''))).setPageSize(10))));

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/algemenegegevens/components/08066c36b2144cd1bcceda99e775d988/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('08066c36b2144cd1bcceda99e775d988', (await Backendless.Data.of('bewonerskenmerken').find(Backendless.DataQueryBuilder.create().setPageSize(10))));

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/algemenegegevens/components/baca2ba2d4e3dc0b1d81096f49b99748/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('8641d099117cce4c8724f4fb0b27bc45')), 'display')) == false) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8641d099117cce4c8724f4fb0b27bc45', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8641d099117cce4c8724f4fb0b27bc45', false);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/algemenegegevens/components/d67a9e7990547cc2091f96d65bade0d8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('baf5442d35d64f5d91c35d423af29acf'), 'datumwijziging')) == null) {
    (function (message) { alert(message) })('De datum is nog niet ingevuld');
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('baf5442d35d64f5d91c35d423af29acf'), 'nieuwaantalbedden')) == null) {
    (function (message) { alert(message) })('Het nieuwe aantal plaatsen/ bedden is nog niet ingevuld');
  } else {
    await Backendless.Data.of('wijzigingbedden').save( ({ 'datumwijziging': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('baf5442d35d64f5d91c35d423af29acf'), 'datumwijziging')),'nieuwaantalbedden': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('baf5442d35d64f5d91c35d423af29acf'), 'nieuwaantalbedden')),'hospice': (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'hospice')) }) );
    await Backendless.Data.of('algemenegegevens').bulkUpdate( (['hospice=\'',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'hospice')),'\''].join('')), ({ 'aantalbedden': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('baf5442d35d64f5d91c35d423af29acf'), 'nieuwaantalbedden')) }) );
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d75477565dfc88fc99f5f35d59c2a709', (await Backendless.Data.of('algemenegegevens').find(Backendless.DataQueryBuilder.create().setWhereClause((['hospice=\'',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'hospice')),'\''].join(''))).setPageSize(10))));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8641d099117cce4c8724f4fb0b27bc45', false);
    ___arguments.context.getComponentDataStoreByUid('baf5442d35d64f5d91c35d423af29acf')['datumwijziging'] = null;
    ___arguments.context.getComponentDataStoreByUid('baf5442d35d64f5d91c35d423af29acf')['nieuwaantalbedden'] = null;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/algemenegegevens/components/9579e0a948072adb5873615b40141b44/bundle.js', [], () => ({
  /* content */
  /* handler:onBlur */
  async ['onBlur'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('wijzigingbedden').save( ({ 'datumwijziging': (new Date()),'nieuwaantalbedden': (getObjectProperty(___arguments.context.pageData, 'aantalbedden')),'hospice': (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'hospice')) }) );

  },
  /* handler:onBlur */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('algemenegegevens').bulkUpdate( (['hospice=\'',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'hospice')),'\''].join('')), ({ 'aantalbedden': ___arguments.value }) );
  ___arguments.context.pageData['aantalbedden'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/algemenegegevens/components/27fc7c7c7b75e3e6d625148ad6d3c9ef/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('algemenegegevens').bulkUpdate( (['hospice=\'',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'hospice')),'\''].join('')), ({ 'soorthospice': ___arguments.value }) );

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/changeclient/components/ec6ed7bab785b0eb033be9b7d1040f5d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var datumoverlijdenofvertrek, overledenofvertrek;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('16fbf577616ba29a27475fe1fed99c8e')), 'display')) == true && (getObjectProperty(___arguments.context.dataModel, 'leeftijd')) == null) {
    (function (message) { alert(message) })('De leeftijd dient ingevuld te worden');
  } else if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('ba6a4051219ffd4ee68490dd2148b2c1')), 'display')) == true && (getObjectProperty(___arguments.context.dataModel, 'zorgsettingvooropname')) == null) {
    (function (message) { alert(message) })('De zorgsetting voor opname dient ingevuld te worden');
  } else if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('6738aecdae8610e75d9b1d82f433ffb8')), 'display')) == true && (getObjectProperty(___arguments.context.dataModel, 'zorgsettingwelkziekenhuis')) == null) {
    (function (message) { alert(message) })('Welk ziekenhuis dient ingevuld te worden');
  } else if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('1c3fcb01a83c3096352406641ab438aa')), 'display')) == true && (getObjectProperty(___arguments.context.dataModel, 'overledenofvertrek')) == null) {
    (function (message) { alert(message) })('Of de cliënt is overleden of vertrokken dient ingevuld te worden');
  } else if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a1e82e5af66cf8087a81f2c6b458f8de')), 'display')) == true && (getObjectProperty(___arguments.context.dataModel, 'overledenofvertrekwaarnaar')) == null) {
    (function (message) { alert(message) })('Waar de cliënt naar vertrokken is dient ingevuld te worden');
  } else if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('79d975b6739448924eccb88c900b0749')), 'display')) == true && (getObjectProperty(___arguments.context.dataModel, 'datumopname')) == null) {
    (function (message) { alert(message) })('De datum van opname dient ingevuld te worden');
  } else if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b55d09849674a6f6a32e1515e003a3ce')), 'display')) == true && (getObjectProperty(___arguments.context.dataModel, 'datumoverlijdenofvertrek')) == null) {
    (function (message) { alert(message) })('De datum van overlijden of vertrek dient ingevuld te worden');
  } else if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('d36d7801adee7d7377243faea5bfcbe4')), 'display')) == true && (getObjectProperty(___arguments.context.dataModel, 'datumaanmelding')) == null) {
    (function (message) { alert(message) })('De datum van aanmelding dient ingevuld te worden');
  } else if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('46cf9fb564424e6f279f9b81515b7b5c')), 'display')) == true && (getObjectProperty(___arguments.context.dataModel, 'datumannulering')) == null) {
    (function (message) { alert(message) })('De datum van annulering dient ingevuld te worden');
  } else if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('382d75a85e5e00c1cb28c5c754d7e094')), 'display')) == true && (getObjectProperty(___arguments.context.dataModel, 'redenannulering')) == null) {
    (function (message) { alert(message) })('De reden van annulering dient ingevuld te worden');
  } else {
    if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('382d75a85e5e00c1cb28c5c754d7e094')), 'display')) == true) {
      overledenofvertrek = 'Opname geannuleerd';
      datumoverlijdenofvertrek = (getObjectProperty(___arguments.context.dataModel, 'datumannulering'));
    } else {
      overledenofvertrek = (getObjectProperty(___arguments.context.dataModel, 'overledenofvertrek'));
      datumoverlijdenofvertrek = (getObjectProperty(___arguments.context.dataModel, 'datumoverlijdenofvertrek'));
    }
    ___arguments.context.pageData['disablesave'] = true;
    ___arguments.context.pageData['createdrecord'] = (await Backendless.Data.of('clienten').bulkUpdate( (['clientnummer=\'',(JSON.parse((getObjectProperty(___arguments.context.appData, 'clientnummer')))),'\''].join('')), ({ 'hospice': (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'hospice')),'kenmerk': (getObjectProperty(___arguments.context.dataModel, 'kenmerk')),'datumaanmelding': (getObjectProperty(___arguments.context.dataModel, 'datumaanmelding')),'datumannulering': (getObjectProperty(___arguments.context.dataModel, 'datumannulering')),'redenannulering': (getObjectProperty(___arguments.context.dataModel, 'redenannulering')),'redenannuleringtoelichting': (getObjectProperty(___arguments.context.dataModel, 'redenannuleringtoelichting')),'leeftijd': (getObjectProperty(___arguments.context.dataModel, 'leeftijd')),'zorgsettingvooropname': (getObjectProperty(___arguments.context.dataModel, 'zorgsettingvooropname')),'zorgsettingwelkziekenhuis': (getObjectProperty(___arguments.context.dataModel, 'zorgsettingwelkziekenhuis')),'overledenofvertrek': overledenofvertrek,'overledenofvertrekwaarnaar': (getObjectProperty(___arguments.context.dataModel, 'overledenofvertrekwaarnaar')),'datumopname': (getObjectProperty(___arguments.context.dataModel, 'datumopname')),'datumoverlijdenofvertrek': datumoverlijdenofvertrek }) ));
    (function (message) { alert(message) })('De wijzigingen zijn opgeslagen');
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('hospiceclienten', undefined);
  }

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  onDisabledStateAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'disablesave')) == true ? true : false)

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/changeclient/components/da41d212ae6863d38204da52c1b9e1f8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('hospiceclienten', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/changeclient/components/f9fe51eeb3c7a45d74551f98e3b8b37e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('hoofdpaginagebruiker', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/changeclient/components/b63c7f7325b6bc2cd96ad1f5a1228c75/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (message) { alert(message) })([(getObjectProperty(___arguments.context.pageData, 'uren')), (getObjectProperty(___arguments.context.pageData, 'kilometers')), (getObjectProperty(___arguments.context.pageData, 'bedrag')), (getObjectProperty(___arguments.context.pageData, 'bedragtitel')), (getObjectProperty(___arguments.context.pageData, 'bedragomschrijving'))]);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/changeclient/components/9e0a707dbfadf7173f3ef59d3c2c41ee/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'zorgsettingvooropname')) == 'Ziekenhuis' ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/changeclient/components/6738aecdae8610e75d9b1d82f433ffb8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'zorgsettingvooropname')) == 'Ziekenhuis' ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/changeclient/components/a1e82e5af66cf8087a81f2c6b458f8de/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.dataModel, 'overledenofvertrek')) == 'De cliënt is vertrokken' ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/changeclient/components/b181ee54ed538fde3029a95e3f8d8d0a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.dataModel, 'overledenofvertrek')) == 'De cliënt is vertrokken' ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/changeclient/components/1c3fcb01a83c3096352406641ab438aa/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.dataModel['overledenofvertrekwaarnaar'] = null;
  ___arguments.context.dataModel['datumoverlijdenofvertrek'] = null;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/changeclient/components/8a02e27e7068684c8924da87d09fc544/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (JSON.parse((getObjectProperty(___arguments.context.appData, 'clientnummer'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/changeclient/components/a934c07ff3aa5d343887dd4ffb661406/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('a934c07ff3aa5d343887dd4ffb661406', (await Backendless.Data.of('clienten').find(Backendless.DataQueryBuilder.create().setWhereClause((['hospice=\'',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'hospice')),'\' AND clientnummer=\'',(JSON.parse((getObjectProperty(___arguments.context.appData, 'clientnummer')))),'\''].join(''))).setPageSize(100))));

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/changeclient/components/0d3212035fe30c3a1f46dc4dd0ffe815/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (JSON.parse((getObjectProperty(___arguments.context.appData, 'status'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/changeclient/components/13461817ad3fe3074de521c90b838a95/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var visible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.dataModel, 'datumannulering')) == !null) {
    visible = false;
  } else if ((getObjectProperty(___arguments.context.dataModel, 'overledenofvertrek')) == 'Opname geannuleerd') {
    visible = false;
  } else if ((getObjectProperty(___arguments.context.dataModel, 'overledenofvertrek')) == 'De cliënt verblijft nog in het hospice') {
    visible = false;
  } else if ((getObjectProperty(___arguments.context.dataModel, 'overledenofvertrek')) == 'De cliënt is vertrokken') {
    visible = true;
  } else if ((getObjectProperty(___arguments.context.dataModel, 'overledenofvertrek')) == 'De cliënt is overleden') {
    visible = true;
  }

  return visible

  },
  /* handler:onDisplayAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var text;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.dataModel, 'overledenofvertrek')) == null) {
    text = 'Datum overlijden of vertrek';
  } else if ((getObjectProperty(___arguments.context.dataModel, 'overledenofvertrek')) == 'De cliënt is overleden') {
    text = 'Datum overlijden';
  } else if ((getObjectProperty(___arguments.context.dataModel, 'overledenofvertrek')) == 'De cliënt is vertrokken') {
    text = 'Datum vertrek';
  }

  return text

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/changeclient/components/b55d09849674a6f6a32e1515e003a3ce/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var visible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.dataModel, 'datumannulering')) == !null) {
    visible = false;
  } else if ((getObjectProperty(___arguments.context.dataModel, 'overledenofvertrek')) == 'Opname geannuleerd') {
    visible = false;
  } else if ((getObjectProperty(___arguments.context.dataModel, 'overledenofvertrek')) == 'De cliënt verblijft nog in het hospice') {
    visible = false;
  } else if ((getObjectProperty(___arguments.context.dataModel, 'overledenofvertrek')) == 'De cliënt is vertrokken') {
    visible = true;
  } else if ((getObjectProperty(___arguments.context.dataModel, 'overledenofvertrek')) == 'De cliënt is overleden') {
    visible = true;
  }

  return visible

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/changeclient/components/b38d0465cc958e05680f4daebcbce598/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('d36d7801adee7d7377243faea5bfcbe4')['datumannulering'] = null;
  ___arguments.context.getComponentDataStoreByUid('d36d7801adee7d7377243faea5bfcbe4')['redenannulering'] = null;
  ___arguments.context.getComponentDataStoreByUid('d36d7801adee7d7377243faea5bfcbe4')['redenannuleringtoelichting'] = null;
  ___arguments.context.getComponentDataStoreByUid('d36d7801adee7d7377243faea5bfcbe4')['datumopname'] = null;
  ___arguments.context.getComponentDataStoreByUid('d36d7801adee7d7377243faea5bfcbe4')['leeftijd'] = null;
  ___arguments.context.getComponentDataStoreByUid('d36d7801adee7d7377243faea5bfcbe4')['zorgsettingvooropname'] = null;
  ___arguments.context.getComponentDataStoreByUid('d36d7801adee7d7377243faea5bfcbe4')['zorgsettingwelkziekenhuis'] = null;
  ___arguments.context.getComponentDataStoreByUid('d36d7801adee7d7377243faea5bfcbe4')['overledenofvertrek'] = null;
  ___arguments.context.getComponentDataStoreByUid('d36d7801adee7d7377243faea5bfcbe4')['overledenofvertrekwaarnaar'] = null;
  ___arguments.context.getComponentDataStoreByUid('d36d7801adee7d7377243faea5bfcbe4')['datumoverlijdenofvertrek'] = null;
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('46cf9fb564424e6f279f9b81515b7b5c')), 'display')) == true) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('86361ee8743da8f7d3cabd1479c4ec88', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('46cf9fb564424e6f279f9b81515b7b5c', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3ce989ba0b8be15dace4294ac82d38a4', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('382d75a85e5e00c1cb28c5c754d7e094', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7fa1fa855046b5d97136ea4566d728df', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a17f3bc9ff6fc84ccc9d50ade4d5857c', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1be3c5a663a90a14c1ccf8722e89db35', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('79d975b6739448924eccb88c900b0749', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a89240e3e29b439e79a3ab4663f293e6', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('16fbf577616ba29a27475fe1fed99c8e', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1123d6126c5f85cf6e4f13fdc6bb8077', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ba6a4051219ffd4ee68490dd2148b2c1', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9e0a707dbfadf7173f3ef59d3c2c41ee', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6738aecdae8610e75d9b1d82f433ffb8', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9be4ec31269652dba6477b081f6b8d9f', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1c3fcb01a83c3096352406641ab438aa', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b181ee54ed538fde3029a95e3f8d8d0a', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a1e82e5af66cf8087a81f2c6b458f8de', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('13461817ad3fe3074de521c90b838a95', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b55d09849674a6f6a32e1515e003a3ce', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('86361ee8743da8f7d3cabd1479c4ec88', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('46cf9fb564424e6f279f9b81515b7b5c', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3ce989ba0b8be15dace4294ac82d38a4', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('382d75a85e5e00c1cb28c5c754d7e094', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7fa1fa855046b5d97136ea4566d728df', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a17f3bc9ff6fc84ccc9d50ade4d5857c', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1be3c5a663a90a14c1ccf8722e89db35', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('79d975b6739448924eccb88c900b0749', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a89240e3e29b439e79a3ab4663f293e6', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('16fbf577616ba29a27475fe1fed99c8e', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1123d6126c5f85cf6e4f13fdc6bb8077', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ba6a4051219ffd4ee68490dd2148b2c1', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9e0a707dbfadf7173f3ef59d3c2c41ee', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6738aecdae8610e75d9b1d82f433ffb8', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9be4ec31269652dba6477b081f6b8d9f', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1c3fcb01a83c3096352406641ab438aa', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b181ee54ed538fde3029a95e3f8d8d0a', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a1e82e5af66cf8087a81f2c6b458f8de', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('13461817ad3fe3074de521c90b838a95', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b55d09849674a6f6a32e1515e003a3ce', false);
  }

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    var text;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d36d7801adee7d7377243faea5bfcbe4'), 'datumannulering')) == null) {
    text = 'Opname geannuleerd';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d36d7801adee7d7377243faea5bfcbe4'), 'datumannulering')) != null) {
    text = 'Annulering opname ongedaan maken';
  }

  return text

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/changeclient/components/a17f3bc9ff6fc84ccc9d50ade4d5857c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var visible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.dataModel, 'redenannulering')) == 'Overgeplaatst naar een ander hospice') {
    visible = true;
  } else if ((getObjectProperty(___arguments.context.dataModel, 'redenannulering')) == 'Verpleeghuis') {
    visible = true;
  } else if ((getObjectProperty(___arguments.context.dataModel, 'redenannulering')) == 'Anders') {
    visible = true;
  } else {
    visible = false;
  }

  return visible

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/changeclient/components/7fa1fa855046b5d97136ea4566d728df/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var visible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.dataModel, 'redenannulering')) == 'Overgeplaatst naar een ander hospice') {
    visible = true;
  } else if ((getObjectProperty(___arguments.context.dataModel, 'redenannulering')) == 'Verpleeghuis') {
    visible = true;
  } else if ((getObjectProperty(___arguments.context.dataModel, 'redenannulering')) == 'Anders') {
    visible = true;
  } else {
    visible = false;
  }

  return visible

  },
  /* handler:onDisplayAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var text;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.dataModel, 'redenannulering')) == 'Overgeplaatst naar een ander hospice') {
    text = 'Naam hospice:';
  } else if ((getObjectProperty(___arguments.context.dataModel, 'redenannulering')) == 'Verpleeghuis') {
    text = 'Naam verpleeghuis:';
  } else if ((getObjectProperty(___arguments.context.dataModel, 'redenannulering')) == 'Anders') {
    text = 'Eventuele toelichting:';
  }

  return text

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/changeclient/components/3056a7366b5aad815f98bddbfa44fe6a/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.dataModel, 'datumannulering')) == null) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1be3c5a663a90a14c1ccf8722e89db35', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('79d975b6739448924eccb88c900b0749', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a89240e3e29b439e79a3ab4663f293e6', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('16fbf577616ba29a27475fe1fed99c8e', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1123d6126c5f85cf6e4f13fdc6bb8077', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ba6a4051219ffd4ee68490dd2148b2c1', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9be4ec31269652dba6477b081f6b8d9f', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1c3fcb01a83c3096352406641ab438aa', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1be3c5a663a90a14c1ccf8722e89db35', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('79d975b6739448924eccb88c900b0749', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a89240e3e29b439e79a3ab4663f293e6', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('16fbf577616ba29a27475fe1fed99c8e', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1123d6126c5f85cf6e4f13fdc6bb8077', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ba6a4051219ffd4ee68490dd2148b2c1', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9be4ec31269652dba6477b081f6b8d9f', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1c3fcb01a83c3096352406641ab438aa', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('86361ee8743da8f7d3cabd1479c4ec88', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('46cf9fb564424e6f279f9b81515b7b5c', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3ce989ba0b8be15dace4294ac82d38a4', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('382d75a85e5e00c1cb28c5c754d7e094', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7fa1fa855046b5d97136ea4566d728df', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a17f3bc9ff6fc84ccc9d50ade4d5857c', true);
  }

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/changeclient/components/d36d7801adee7d7377243faea5bfcbe4/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'aanmeldingen')) == true ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/changeclient/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      if (!(await Backendless.UserService.isValidLogin())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('inlogpagina', undefined);
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/createclient/components/ec6ed7bab785b0eb033be9b7d1040f5d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var datumoverlijdenofvertrek, overledenofvertrek;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('16fbf577616ba29a27475fe1fed99c8e')), 'display')) == true && (getObjectProperty(___arguments.context.pageData, 'leeftijd')) == null) {
    (function (message) { alert(message) })('De leeftijd dient ingevuld te worden');
  } else if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('ba6a4051219ffd4ee68490dd2148b2c1')), 'display')) == true && (getObjectProperty(___arguments.context.pageData, 'zorgsettingvooropname')) == null) {
    (function (message) { alert(message) })('De zorgsetting voor opname dient ingevuld te worden');
  } else if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('6738aecdae8610e75d9b1d82f433ffb8')), 'display')) == true && (getObjectProperty(___arguments.context.pageData, 'zorgsettingwelkziekenhuis')) == null) {
    (function (message) { alert(message) })('Welk ziekenhuis dient ingevuld te worden');
  } else if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('1c3fcb01a83c3096352406641ab438aa')), 'display')) == true && (getObjectProperty(___arguments.context.pageData, 'overledenofvertrek')) == null) {
    (function (message) { alert(message) })('Of de cliënt is overleden of vertrokken dient ingevuld te worden');
  } else if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a1e82e5af66cf8087a81f2c6b458f8de')), 'display')) == true && (getObjectProperty(___arguments.context.pageData, 'overledenofvertrekwaarnaar')) == null) {
    (function (message) { alert(message) })('Waar de cliënt naar vertrokken is dient ingevuld te worden');
  } else if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a32180de7ee9bc0fae2e817ead26aef6')), 'display')) == true && (getObjectProperty(___arguments.context.pageData, 'datumopname')) == null) {
    (function (message) { alert(message) })('De datum van opname dient ingevuld te worden');
  } else if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b55d09849674a6f6a32e1515e003a3ce')), 'display')) == true && (getObjectProperty(___arguments.context.pageData, 'datumoverlijdenofvertrek')) == null) {
    (function (message) { alert(message) })('De datum van overlijden of vertrek dient ingevuld te worden');
  } else if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('5e61d759ab962ff86d49a5181a7aadb4')), 'display')) == true && (getObjectProperty(___arguments.context.pageData, 'datumaanmelding')) == null) {
    (function (message) { alert(message) })('De datum van aanmelding dient ingevuld te worden');
  } else if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('aad597adb7fab92177593714eb8381c8')), 'display')) == true && (getObjectProperty(___arguments.context.pageData, 'datumannulering')) == null) {
    (function (message) { alert(message) })('De datum van annulering dient ingevuld te worden');
  } else if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('bd48131ef0eb34d112de820617ac8fb5')), 'display')) == true && (getObjectProperty(___arguments.context.pageData, 'redenannulering')) == null) {
    (function (message) { alert(message) })('De reden van annulering dient ingevuld te worden');
  } else {
    if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('bd48131ef0eb34d112de820617ac8fb5')), 'display')) == true) {
      overledenofvertrek = 'Opname geannuleerd';
      datumoverlijdenofvertrek = (getObjectProperty(___arguments.context.pageData, 'datumannulering'));
    } else {
      overledenofvertrek = (getObjectProperty(___arguments.context.pageData, 'overledenofvertrek'));
      datumoverlijdenofvertrek = (getObjectProperty(___arguments.context.pageData, 'datumoverlijdenofvertrek'));
    }
    ___arguments.context.pageData['disablesave'] = true;
    ___arguments.context.pageData['createdrecord'] = (await Backendless.Data.of('clienten').save( ({ 'hospice': (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'hospice')),'kenmerk': (getObjectProperty(___arguments.context.pageData, 'kenmerk')),'datumaanmelding': (getObjectProperty(___arguments.context.pageData, 'datumaanmelding')),'datumannulering': (getObjectProperty(___arguments.context.pageData, 'datumannulering')),'redenannulering': (getObjectProperty(___arguments.context.pageData, 'redenannulering')),'redenannuleringtoelichting': (getObjectProperty(___arguments.context.pageData, 'redenannuleringtoelichting')),'leeftijd': (getObjectProperty(___arguments.context.pageData, 'leeftijd')),'zorgsettingvooropname': (getObjectProperty(___arguments.context.pageData, 'zorgsettingvooropname')),'zorgsettingwelkziekenhuis': (getObjectProperty(___arguments.context.pageData, 'zorgsettingwelkziekenhuis')),'overledenofvertrek': overledenofvertrek,'overledenofvertrekwaarnaar': (getObjectProperty(___arguments.context.pageData, 'overledenofvertrekwaarnaar')),'datumopname': (getObjectProperty(___arguments.context.pageData, 'datumopname')),'datumoverlijdenofvertrek': datumoverlijdenofvertrek }) ));
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('hospiceclienten', undefined);
  }

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  onDisabledStateAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'disablesave')) == true ? true : false)

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/createclient/components/da41d212ae6863d38204da52c1b9e1f8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('hospiceclienten', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/createclient/components/f9fe51eeb3c7a45d74551f98e3b8b37e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('hoofdpaginagebruiker', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/createclient/components/b63c7f7325b6bc2cd96ad1f5a1228c75/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (message) { alert(message) })([(getObjectProperty(___arguments.context.pageData, 'uren')), (getObjectProperty(___arguments.context.pageData, 'kilometers')), (getObjectProperty(___arguments.context.pageData, 'bedrag')), (getObjectProperty(___arguments.context.pageData, 'bedragtitel')), (getObjectProperty(___arguments.context.pageData, 'bedragomschrijving'))]);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/createclient/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
    var parameters;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(await Backendless.UserService.isValidLogin())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('inlogpagina', undefined);
  }
  if ((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'rol')) == 'NC') {
    ___arguments.context.pageData['soort'] = 'Vaste vacatiegelden';
    ___arguments.context.pageData['uren'] = null;
    ___arguments.context.pageData['kilometers'] = null;
    ___arguments.context.pageData['bedraginput'] = null;
    ___arguments.context.pageData['betreft'] = null;
    ___arguments.context.pageData['omschrijving'] = null;
    parameters = (await Promise.all((await Backendless.Data.of('soort').find(Backendless.DataQueryBuilder.create().setWhereClause((['soort=\'',(getObjectProperty(___arguments.context.pageData, 'soort')),'\''].join(''))).setPageSize(100))).map(async parameters => {; return ({ 'uren': (getObjectProperty(parameters, 'auren')),'kilometers': (getObjectProperty(parameters, 'akilometers')),'bedrag': (getObjectProperty(parameters, 'abedrag')),'betreft': (getObjectProperty(parameters, 'abetreft')),'omschrijving': (getObjectProperty(parameters, 'aomschrijving')),'bedragtitel': (getObjectProperty(parameters, 'bedragtitel')),'bedragomschrijving': (getObjectProperty(parameters, 'bedragomschrijving')) });})))[0];
    ___arguments.context.pageData['paramuren'] = (getObjectProperty(parameters, 'uren'));
    ___arguments.context.pageData['paramkilometers'] = (getObjectProperty(parameters, 'kilometers'));
    ___arguments.context.pageData['parambedrag'] = (getObjectProperty(parameters, 'bedrag'));
    ___arguments.context.pageData['parambetreft'] = (getObjectProperty(parameters, 'betreft'));
    ___arguments.context.pageData['paramomschrijving'] = (getObjectProperty(parameters, 'omschrijving'));
    ___arguments.context.pageData['bedragtitel'] = (getObjectProperty(parameters, 'bedragtitel'));
    ___arguments.context.pageData['bedragomschrijving'] = (getObjectProperty(parameters, 'bedragomschrijving'));
    if ((getObjectProperty(___arguments.context.pageData, 'soort')) == 'Vaste vacatiegelden') {
      ___arguments.context.pageData['bedraginput'] = 200;
    } else {
      ___arguments.context.pageData['bedraginput'] = null;
      ___arguments.context.pageData['uren'] = null;
      ___arguments.context.pageData['kilometers'] = null;
    }
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/createclient/components/9e0a707dbfadf7173f3ef59d3c2c41ee/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'zorgsettingvooropname')) == 'Ziekenhuis' ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/createclient/components/6738aecdae8610e75d9b1d82f433ffb8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'zorgsettingvooropname')) == 'Ziekenhuis' ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    
  },
  /* handler:onChange */
  /* content */
}))

define('./pages/createclient/components/a1e82e5af66cf8087a81f2c6b458f8de/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'overledenofvertrek')) == 'De cliënt is vertrokken' ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/createclient/components/b181ee54ed538fde3029a95e3f8d8d0a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'overledenofvertrek')) == 'De cliënt is vertrokken' ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/createclient/components/ba6a4051219ffd4ee68490dd2148b2c1/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['zorgsettingwelkziekenhuis'] = null;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/createclient/components/1c3fcb01a83c3096352406641ab438aa/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['overledenofvertrekwaarnaar'] = null;
  ___arguments.context.pageData['datumoverlijdenofvertrek'] = null;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/createclient/components/13461817ad3fe3074de521c90b838a95/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'overledenofvertrek')) == 'De cliënt is vertrokken' ? true : ((getObjectProperty(___arguments.context.pageData, 'overledenofvertrek')) == 'De cliënt is overleden' ? true : false))

  },
  /* handler:onDisplayAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var text;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'overledenofvertrek')) == null) {
    text = 'Datum overlijden of vertrek';
  } else if ((getObjectProperty(___arguments.context.pageData, 'overledenofvertrek')) == 'De cliënt is overleden') {
    text = 'Datum overlijden';
  } else if ((getObjectProperty(___arguments.context.pageData, 'overledenofvertrek')) == 'De cliënt is vertrokken') {
    text = 'Datum vertrek';
  }

  return text

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/createclient/components/b55d09849674a6f6a32e1515e003a3ce/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'overledenofvertrek')) == 'De cliënt is vertrokken' ? true : ((getObjectProperty(___arguments.context.pageData, 'overledenofvertrek')) == 'De cliënt is overleden' ? true : false))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/createclient/components/5e9101d7bf8a12d81adbc67442cf9536/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var text;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'redenannulering')) == 'Overgeplaatst naar een ander hospice') {
    text = 'Naam hospice:';
  } else if ((getObjectProperty(___arguments.context.pageData, 'redenannulering')) == 'Verpleeghuis') {
    text = 'Naam verpleeghuis:';
  } else if ((getObjectProperty(___arguments.context.pageData, 'redenannulering')) == 'Anders') {
    text = 'Eventuele toelichting:';
  }

  return text

  },
  /* handler:onContentAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var visible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'redenannulering')) == 'Overgeplaatst naar een ander hospice') {
    visible = true;
  } else if ((getObjectProperty(___arguments.context.pageData, 'redenannulering')) == 'Verpleeghuis') {
    visible = true;
  } else if ((getObjectProperty(___arguments.context.pageData, 'redenannulering')) == 'Anders') {
    visible = true;
  } else {
    visible = false;
  }

  return visible

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/createclient/components/29ffb2f91f2620fbe4de4a3e4ee76369/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var visible;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'redenannulering')) == 'Overgeplaatst naar een ander hospice') {
    visible = true;
  } else if ((getObjectProperty(___arguments.context.pageData, 'redenannulering')) == 'Verpleeghuis') {
    visible = true;
  } else if ((getObjectProperty(___arguments.context.pageData, 'redenannulering')) == 'Anders') {
    visible = true;
  } else {
    visible = false;
  }

  return visible

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/createclient/components/efda7ec3938a096802fec9f4ab6451a4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['datumannulering'] = null;
  ___arguments.context.pageData['redenannulering'] = null;
  ___arguments.context.pageData['redenannuleringtoelichting'] = null;
  ___arguments.context.pageData['datumopname'] = null;
  ___arguments.context.pageData['zorgsettingwelkziekenhuis'] = null;
  ___arguments.context.pageData['overledenofvertrek'] = null;
  ___arguments.context.pageData['overledenofvertrekwaarnaar'] = null;
  ___arguments.context.pageData['datumoverlijdenofvertrek'] = null;
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a32180de7ee9bc0fae2e817ead26aef6')), 'display')) == true) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9a75ca82269a9d56f64d38543fbdb55a', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('aad597adb7fab92177593714eb8381c8', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8adf30aaf608ce2275185609460261df', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bd48131ef0eb34d112de820617ac8fb5', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5e9101d7bf8a12d81adbc67442cf9536', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('29ffb2f91f2620fbe4de4a3e4ee76369', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5c946c2bd1753cfea6e778e65f27eee3', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a32180de7ee9bc0fae2e817ead26aef6', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a89240e3e29b439e79a3ab4663f293e6', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('16fbf577616ba29a27475fe1fed99c8e', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1123d6126c5f85cf6e4f13fdc6bb8077', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ba6a4051219ffd4ee68490dd2148b2c1', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9be4ec31269652dba6477b081f6b8d9f', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1c3fcb01a83c3096352406641ab438aa', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9a75ca82269a9d56f64d38543fbdb55a', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('aad597adb7fab92177593714eb8381c8', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8adf30aaf608ce2275185609460261df', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bd48131ef0eb34d112de820617ac8fb5', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5e9101d7bf8a12d81adbc67442cf9536', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('29ffb2f91f2620fbe4de4a3e4ee76369', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5c946c2bd1753cfea6e778e65f27eee3', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a32180de7ee9bc0fae2e817ead26aef6', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a89240e3e29b439e79a3ab4663f293e6', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('16fbf577616ba29a27475fe1fed99c8e', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1123d6126c5f85cf6e4f13fdc6bb8077', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ba6a4051219ffd4ee68490dd2148b2c1', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9be4ec31269652dba6477b081f6b8d9f', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1c3fcb01a83c3096352406641ab438aa', true);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/createclient/components/635b9b3cfa0449ff4e743a3953ee758a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['datumannulering'] = null;
  ___arguments.context.pageData['redenannulering'] = null;
  ___arguments.context.pageData['redenannuleringtoelichting'] = null;
  ___arguments.context.pageData['datumopname'] = null;
  ___arguments.context.pageData['leeftijd'] = null;
  ___arguments.context.pageData['zorgsettingvooropname'] = null;
  ___arguments.context.pageData['zorgsettingwelkziekenhuis'] = null;
  ___arguments.context.pageData['overledenofvertrek'] = null;
  ___arguments.context.pageData['overledenofvertrekwaarnaar'] = null;
  ___arguments.context.pageData['datumoverlijdenofvertrek'] = null;
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('aad597adb7fab92177593714eb8381c8')), 'display')) == true) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9a75ca82269a9d56f64d38543fbdb55a', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('aad597adb7fab92177593714eb8381c8', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8adf30aaf608ce2275185609460261df', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bd48131ef0eb34d112de820617ac8fb5', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5e9101d7bf8a12d81adbc67442cf9536', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('29ffb2f91f2620fbe4de4a3e4ee76369', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5c946c2bd1753cfea6e778e65f27eee3', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a32180de7ee9bc0fae2e817ead26aef6', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a89240e3e29b439e79a3ab4663f293e6', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('16fbf577616ba29a27475fe1fed99c8e', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1123d6126c5f85cf6e4f13fdc6bb8077', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ba6a4051219ffd4ee68490dd2148b2c1', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9e0a707dbfadf7173f3ef59d3c2c41ee', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6738aecdae8610e75d9b1d82f433ffb8', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9be4ec31269652dba6477b081f6b8d9f', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1c3fcb01a83c3096352406641ab438aa', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b181ee54ed538fde3029a95e3f8d8d0a', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a1e82e5af66cf8087a81f2c6b458f8de', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('13461817ad3fe3074de521c90b838a95', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b55d09849674a6f6a32e1515e003a3ce', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9a75ca82269a9d56f64d38543fbdb55a', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('aad597adb7fab92177593714eb8381c8', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8adf30aaf608ce2275185609460261df', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bd48131ef0eb34d112de820617ac8fb5', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5e9101d7bf8a12d81adbc67442cf9536', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('29ffb2f91f2620fbe4de4a3e4ee76369', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5c946c2bd1753cfea6e778e65f27eee3', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a32180de7ee9bc0fae2e817ead26aef6', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a89240e3e29b439e79a3ab4663f293e6', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('16fbf577616ba29a27475fe1fed99c8e', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1123d6126c5f85cf6e4f13fdc6bb8077', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ba6a4051219ffd4ee68490dd2148b2c1', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9e0a707dbfadf7173f3ef59d3c2c41ee', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6738aecdae8610e75d9b1d82f433ffb8', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9be4ec31269652dba6477b081f6b8d9f', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1c3fcb01a83c3096352406641ab438aa', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b181ee54ed538fde3029a95e3f8d8d0a', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a1e82e5af66cf8087a81f2c6b458f8de', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('13461817ad3fe3074de521c90b838a95', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b55d09849674a6f6a32e1515e003a3ce', false);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/createclient/components/5e61d759ab962ff86d49a5181a7aadb4/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'aanmeldingen')) == true ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/helpdesk/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(await Backendless.UserService.isValidLogin())) {
    await ( async function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('inlogpagina', undefined);
  }
  ___arguments.context.pageData['filter'] = (['username=\'',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'username')),'\'','AND aanmaakmaand=\'',(new Date((new Date())).getMonth() + 1),'\''].join(''));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/helpdesk/components/da41d212ae6863d38204da52c1b9e1f8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('hoofdpaginagebruiker', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/helpdesk/components/f9fe51eeb3c7a45d74551f98e3b8b37e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('hoofdpaginagebruiker', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/helpdesk/components/71bb8c0f36a703f62f7844d46b446c8d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function ensureArray(items) {
  return Array.isArray(items) ? items : [items]
}


  await Backendless.Messaging.sendEmail((String('Helpdesk vraag van Hospices_Arnhem - ') + String(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'naam'))), new Backendless.Bodyparts({ htmlmessage: (['<body>','Helpdesk vraag van:  ',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'naam')),'<body>','Gebruikersnaam:  ',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'username')),'<body>','Email:  ',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'email')),'<body>','Hospice:  ',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'hospice')),'<body><br>','Vraag:  ',(getObjectProperty(___arguments.context.pageData, 'email'))].join('')) }), ensureArray('info@lorio.nl'), undefined);
  ___arguments.context.pageData['email'] = 'DE EMAIL IS VERZONDEN';
  await new Promise(r => setTimeout(r, 3000 || 0));
  (function () { window.history.back(); })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/hoofdpaginagebruiker/components/b4e8b57329ba9b3c520c9c2433d24fb9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('hospiceclienten', undefined);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'clienten'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/hoofdpaginagebruiker/components/525d3c3e9ae354e50069c3388b04f3ea/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://lookerstudio.google.com/reporting/41120622-aa74-4e9e-8ac9-1ea0d8914dc5', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/hoofdpaginagebruiker/components/b1552a32f7853c686a04402fd58dcf95/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('algemenegegevens', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/hoofdpaginagebruiker/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(await Backendless.UserService.isValidLogin())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('inlogpagina', undefined);
  }
  await Backendless.Data.of('factuurgegevens').bulkUpdate( (['username=\'',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'username')),'\''].join('')), ({ 'ownerId': (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'ownerId')) }) );
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('6d31925bbd59cf537b5e7f9ad9fd5c57', (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['username=\'',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'username')),'\''].join(''))).setPageSize(10))));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('f1c7a324f6e1a1bec67bcf692cf8ca7a', (await Backendless.Data.of('tarieven').find(Backendless.DataQueryBuilder.create().setWhereClause((['organisatie=\'',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'organisatie')),'\''].join(''))).setPageSize(10))));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/hoofdpaginagebruiker/components/0021ac391567825f0b2fb66f67c5de5a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('maandoverzicht', undefined);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'declaraties'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/hoofdpaginagebruiker/components/4a7870b61279d126f4c477f2dc77f1a1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('helpdesk', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/hoofdpaginagebruiker/components/1b84a1e44048d2c8b2d1e4acfc0ffb43/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await Backendless.UserService.logout();
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('inlogpagina', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/hoofdpaginagebruiker/components/2d8116c1f317173162ec3b1ed6431e38/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('declaratiesindienen', undefined);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'declaraties'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/hoofdpaginagebruiker/components/d5e4004805ab103a37114e53854ae9c1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('mijndeclaraties', undefined);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'declaraties'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/hospiceclienten/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(await Backendless.UserService.isValidLogin())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('inlogpagina', undefined);
  }
  ___arguments.context.appData['startpaginahospiceclienten'] = 'alleactiviteiten';
  ___arguments.context.pageData['visible'] = (getObjectProperty(___arguments.context.appData, 'startpaginahospiceclienten'));
  ___arguments.context.pageData['statusfilter'] = '%';

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/hospiceclienten/components/da41d212ae6863d38204da52c1b9e1f8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('hoofdpaginagebruiker', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/hospiceclienten/components/f9fe51eeb3c7a45d74551f98e3b8b37e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('hoofdpaginagebruiker', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/hospiceclienten/components/9887f9a249efcf19f323282ee01e0513/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('6a917ad1284f7ad6a7c4f3da784f23e4')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/hospiceclienten/components/e30f956f40595365da44e8c750f3a3f1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('createclient', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/hospiceclienten/components/a67ea79e5edfb40fc14ab7c2968b462e/bundle.js', [], () => ({
  /* content */
  /* handler:onRecordSelect */
  ['onRecordSelect'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.appData['clientnummer'] = (JSON.stringify((getObjectProperty(___arguments.selectedRecord, 'clientnummer'))));
  ___arguments.context.appData['clientobjectid'] = (JSON.stringify((getObjectProperty(___arguments.selectedRecord, 'objectId'))));
  ___arguments.context.appData['status'] = (JSON.stringify((getObjectProperty(___arguments.selectedRecord, 'status'))));
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('changeclient', undefined);

  },
  /* handler:onRecordSelect */
  /* handler:onWhereClauseAssignment */
  async ['onWhereClauseAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['hospice=\'',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'hospice')),'\'',' AND status LIKE\'',(getObjectProperty(___arguments.context.pageData, 'statusfilter')),'\''].join(''))

  },
  /* handler:onWhereClauseAssignment */
  /* handler:onColumnsAssignment */
  async ['onColumnsAssignment'](___arguments) {
    var F, item, E, D, C, B, A;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}



  return (await asyncListFilter((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a67ea79e5edfb40fc14ab7c2968b462e')), 'columns')), async (item) => {
   if ((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'aanmeldingen')) == false) {
    A = 'Datum aanmelding';
  }


 return ((getObjectProperty(item, 'title')) != A);
}))

  },
  /* handler:onColumnsAssignment */
  /* content */
}))

define('./pages/inlogpagina/components/db5546014b7940ad8771a98d85f83036/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, transactionId, totpQR_Path, enableResult, secret;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function enable2FA() {
  enableResult = (await Backendless.Request.put(`${Backendless.appPath}/services/TOTP/enable2fa`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
  totpQR_Path = (getObjectProperty(enableResult, 'totpQR_Path'));
  secret = (getObjectProperty(enableResult, 'secret'));
}

/**
 * Describe this function...
 */
async function setError(error) {
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['error'] = error;
}


  try {
    await Backendless.UserService.login( (getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'username')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'password')), false  );
    ___arguments.context.pageData['transactionId'] = (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'transactionId'));
    if ((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'totpAuthEnabled')) == false) {
      await enable2FA();
      ___arguments.context.pageData['qrURL'] = ([(Backendless.appPath),'/files/',totpQR_Path].join(''));
      transactionId = (getObjectProperty((await Backendless.UserService.login( (getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'username')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'password')), false  )), 'transactionId'));
      ___arguments.context.pageData['transactionId'] = transactionId;
    }

  } catch (error) {
    if ((getObjectProperty(error, 'code')) == 3033) {
    } else if ((getObjectProperty(error, 'code')) == 3003) {
      await setError((new Error('Gebruikersnaam en/of wachtwoord niet correct')));
    } else if ((getObjectProperty(error, 'code')) == 3036) {
      await setError((new Error('Uw account is geblokkeerd vanwege te veel mislukte aanmeldingen. Uw account zal na 24 uur weer toegankelijk zijn. Indien u eerder weer toegang wilt hebben neem dan contact op met info@lorio.nl')));
    } else {
      await setError(error);
    }

  } finally {
    if (!(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'transactionId')).length) {
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a12c454e2886534edcf4393894d22219', false);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3a69240f4cce6798bf5d2c918c74c2f4', true);
      (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('8ce3e81b02364eaa3c0c11e2f895e801')));
    }

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/inlogpagina/components/7dfd192d040ea3656b0b8c42084ff3eb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'username')) == null) {
    (function (message) { alert(message) })('Vul je gebruikersnaam in om het wachtwoord te resetten.');
  } else {
    await Backendless.Request['get']((function(url){ if( !url ) { throw new Error('Url must be specified.')} if( !url.startsWith('http://') && !url.startsWith('https://')) { return 'https://' + url } return url})(([(Backendless.appPath),'/users/restorepassword/',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'username'))].join('')))).setEncoding('utf8').send();
    (function (message) { alert(message) })('Er is een mail verstuurd met een link om het wachtwoord te resetten.');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/inlogpagina/components/9b250b5ac5ef73d362b40c5ef967bc42/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isPasswordVisible'] = (!(getObjectProperty(___arguments.context.pageData, 'isPasswordVisible')));
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b4edd272bd7fb7f6f22eae3525232662'))['type'] = ((getObjectProperty(___arguments.context.pageData, 'isPasswordVisible')) ? 'input' : 'password');

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'isPasswordVisible')) == true) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9b250b5ac5ef73d362b40c5ef967bc42'))['color'] = '#89b087';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9b250b5ac5ef73d362b40c5ef967bc42'))['color'] = '#545C5C';
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/inlogpagina/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  ['onEnter'](___arguments) {
      ___arguments.context.pageData['isPasswordVisible'] = false;
  ___arguments.context.pageData['qrURL'] = null;
  (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('927c9caef221532a3a695d8ca4ded5cb')));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/inlogpagina/components/8abb41ce996c4b251fb9cfd598ae3f9a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setError(error) {
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['error2'] = error;
}


  try {
    await (async function(user, stayLoggedIn) {
    	Backendless.Users.setCurrentUser(user, stayLoggedIn)
    })((await Backendless.Request.post(`${Backendless.appPath}/services/TOTP/auth`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': (Backendless.getCurrentUserToken()) }})).send({ 'transactionId': (getObjectProperty(___arguments.context.pageData, 'transactionId')),'code': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'mfawachtwoord')) })), false);

  } catch (error) {
    if ((getObjectProperty(error, 'code')) == 0) {
      await setError((new Error('De code is niet correct, probeer opnieuw')));
    } else {
      await setError(error);
    }

  } finally {
    if (await Backendless.UserService.isValidLogin()) {
      if ((await Backendless.UserService.getUserRoles()).includes('gebruiker')) {
        (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('hoofdpaginagebruiker', undefined);
      } else if ((await Backendless.UserService.getUserRoles()).includes('beheerder')) {
        (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('hoofdpaginabeheerder', undefined);
      }
    } else {
      await setError((new Error('De code is niet correct, probeer opnieuw')));
    }

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/inlogpagina/components/0ea61c9bfc8fb45df3185d0f605189d1/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'qrURL'))

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/inlogpagina/components/00c12203fb5fb62c8d517401c5968862/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'qrURL')) == null ? false : true)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/inlogpagina/components/a93bf7d2200d316ea8b84df54d54268d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://drive.google.com/file/d/1djBArrai6DniHaFHvoAtTI27atjb_t5p/view?usp=drive_link', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/inlogpagina/components/e25e730f4496710a17447c23a4ebcf75/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'qrURL')) == null ? false : true)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/inlogpagina/components/9cee8611c65586ee7e197b9748a61e4b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((String(Backendless.appPath) + String('/files/privacyverklaring/GebruikersvoorwaardenTestapplicatiev1.0.pdf')), true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/inlogpagina/components/8d13f77ed0f6d6fba43dc24c3881d24e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((String(Backendless.appPath) + String('/files/privacyverklaring/Privacyverklaring Lorio v1.2.pdf')), true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/inlogpaginaoud/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  ['onEnter'](___arguments) {
      ___arguments.context.pageData['isPasswordVisible'] = false;

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/inlogpaginaoud/components/db5546014b7940ad8771a98d85f83036/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setError(error) {
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['error'] = error;
}


  try {
    await Backendless.UserService.login( (getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'username')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'password')), false  );
    if ((await Backendless.UserService.getUserRoles()).includes('gebruiker')) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('hoofdpaginagebruiker', undefined);
    } else if ((await Backendless.UserService.getUserRoles()).includes('beheerder')) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('hoofdpaginabeheerder', undefined);
    }

  } catch (error) {
    if ((getObjectProperty(error, 'code')) == 3033) {
    } else {
      await setError((new Error('Gebruikersnaam en/of wachtwoord niet correct')));
    }

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/inlogpaginaoud/components/6d7b09fc8c04707fa6291e69b80c05d1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'username')) == null) {
    (function (message) { alert(message) })('Vul je gebruikersnaam in om het wachtwoord te resetten.');
  } else {
    await Backendless.Request['get']((function(url){ if( !url ) { throw new Error('Url must be specified.')} if( !url.startsWith('http://') && !url.startsWith('https://')) { return 'https://' + url } return url})(([(Backendless.appPath),'/users/restorepassword/',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'username'))].join('')))).setEncoding('utf8').send();
    (function (message) { alert(message) })('Er is een mail verstuurd met een link om het wachtwoord te resetten.');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/inlogpaginaoud/components/4eee7d87ea27420d1c9a2062870f0bfd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isPasswordVisible'] = (!(getObjectProperty(___arguments.context.pageData, 'isPasswordVisible')));
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b4edd272bd7fb7f6f22eae3525232662'))['type'] = ((getObjectProperty(___arguments.context.pageData, 'isPasswordVisible')) ? 'input' : 'password');

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'isPasswordVisible')) == true) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('4eee7d87ea27420d1c9a2062870f0bfd'))['color'] = '#89b087';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('4eee7d87ea27420d1c9a2062870f0bfd'))['color'] = '#2B472A';
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/inlogpaginaoud/components/e9eb2b64d33c01ca51467acd885cfe45/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    
  },
  /* handler:onClick */
  /* content */
}))

define('./pages/inlogpaginaoud/components/682533bd9bb3d4fe22ffcd0d14e57bbf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((String(Backendless.appPath) + String('/files/privacyverklaring/Privacyverklaring+Lorio+v1.0.pdf')), true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

